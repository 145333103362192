export const plagiarismCheckerPageUrl =
  "https://www.paperrater.com/plagiarism_checker";

export const defaultWebsiteUrl = "https://applecharterbuscompany.com/";

export const webhookUrl =
  "https://cloud.activepieces.com/api/v1/webhooks/vBKvYD7GVTscRqvvPUShg";
//"https://hooks.slack.com/services/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";

export const perImageCharge = 0.025;
